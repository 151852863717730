import React from 'react'
import {
  Box,
  Button,
  Text,
  useColorModeValue,
  Icon,
  useDisclosure,
  Flex,
  IconButton,
  Tooltip
} from '@chakra-ui/react'
import { Track, Version } from '../_helpers/types'
import { FaPlus, FaStar, FaRegStar, FaEllipsisV } from 'react-icons/fa'
import CreateVersion from './project/CreateVersion'
import MarkFinalVersion from './project/MarkFinalVersion'

interface VersionSelectorProps {
  versions: Version[]
  currentVersionIndex: number
  onVersionSelect: (index: number) => void
  track: Track
  isOwner: boolean
  isDisabled: boolean
  onMenuOpen: (version: Version, buttonRect: DOMRect) => void
  canCreateVersion: boolean
}

const VersionSelector: React.FC<VersionSelectorProps> = ({
  versions,
  currentVersionIndex,
  onVersionSelect,
  track,
  isOwner,
  isDisabled,
  onMenuOpen,
  canCreateVersion
}) => {
  const borderColor = useColorModeValue('gray.300', 'gray.300')
  const textColor = useColorModeValue('black', 'black')
  const selectedBgColor = useColorModeValue(
    'rgba(242, 135, 5, 1)',
    'rgba(242, 135, 5, 1)'
  )

  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isMarkFinalVersionOpen,
    onOpen: onMarkFinalVersionOpen,
    onClose: onMarkFinalVersionClose
  } = useDisclosure()

  return (
    <>
      <Box py={2} px={4} borderTopWidth={1} borderColor={borderColor}>
        <Flex overflowX='auto' width='100%'>
          {[...versions].map((version, index) => (
            <Button
              key={version.id}
              onClick={() => onVersionSelect(index)}
              bg={index === currentVersionIndex ? selectedBgColor : 'transparent'}
              color={textColor}
              _hover={{
                bg:
                  index === currentVersionIndex
                    ? selectedBgColor
                    : 'rgba(251, 232, 214, 0.7)'
              }}
              size='sm'
              maxWidth='20%'
              minWidth='10%'
              borderRadius='md'
              variant='outline'
              fontWeight='normal'
              colorScheme='orange'
              px={1}
              flex={1}
              mr={1}
              isDisabled={isDisabled}
            >
              <Flex alignItems='center' justifyContent='space-between' width='100%'>
                <Box flex={1}>
                  <Text fontSize='xs' noOfLines={1} textAlign='center'>
                    {version.name}
                  </Text>
                </Box>
                {isOwner && (
                  <Tooltip
                    label={version.isFinal ? 'Final Version' : 'Mark Version as Final'}
                  >
                    <IconButton
                      icon={
                        <Icon
                          as={version.isFinal ? FaStar : FaRegStar}
                          color={version.isFinal ? 'gold' : 'gray.400'}
                          boxSize={4}
                        />
                      }
                      aria-label={
                        version.isFinal ? 'Final version' : 'Mark as final version'
                      }
                      size='sm'
                      variant='ghost'
                      minWidth='auto'
                      height='auto'
                      _hover={{ bg: 'transparent' }}
                      onClick={(e) => {
                        e.stopPropagation()
                        onMarkFinalVersionOpen()
                      }}
                    />
                  </Tooltip>
                )}
                {isOwner && (
                  <IconButton
                    icon={<Icon as={FaEllipsisV} boxSize={3} />}
                    variant='ghost'
                    size='sm'
                    p={0}
                    bg='transparent'
                    _hover={{ bg: 'transparent' }}
                    _active={{ bg: 'transparent' }}
                    onClick={(e) => {
                      e.stopPropagation()
                      const buttonRect = e.currentTarget.getBoundingClientRect()
                      onMenuOpen(version, buttonRect)
                    }}
                    aria-label='Open version menu'
                  />
                )}
              </Flex>
            </Button>
          ))}
          {isOwner && (
            <Button
              onClick={onOpen}
              bg='transparent'
              color={textColor}
              _hover={{ bg: 'rgba(251, 232, 214, 0.7)' }}
              size='sm'
              borderRadius='md'
              fontWeight='normal'
              variant='outline'
              colorScheme='orange'
              px={3}
              py={1}
              leftIcon={<Icon as={FaPlus} />}
              flexShrink={0}
            >
              <Text fontSize='xs'>Version</Text>
            </Button>
          )}
        </Flex>
      </Box>
      {(canCreateVersion || isOwner) && (
        <CreateVersion isOpen={isOpen} onClose={onClose} trackData={track} />
      )}
      <MarkFinalVersion
        isOpen={isMarkFinalVersionOpen}
        onClose={onMarkFinalVersionClose}
        track={track}
        versions={versions}
      />
    </>
  )
}

export default VersionSelector
