import React, { useState } from 'react'
import {
  Button,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
  useDisclosure
} from '@chakra-ui/react'
import { FaComment } from 'react-icons/fa'
import emailjs from '@emailjs/browser'

const FeedbackButton: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    source: '',
    feedback: ''
  })
  const toast = useToast()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async () => {
    const templateParams = {
      name: formData.name,
      email: formData.email,
      source: formData.source,
      feedback: formData.feedback
    }
    // TODO: setup backend api endpoint to send email
    const serviceID = 'service_5kz2csq'
    const templateID = 'template_h40uzem'
    const userPublicKey = 'Th0mry8sH3us0Uv05'
    emailjs.send(serviceID, templateID, templateParams, {
      publicKey: userPublicKey
    })

    toast({
      title: 'Feedback sent successfully',
      description: 'Thank you for your feedback!',
      status: 'success',
      duration: 5000,
      isClosable: true
    })
    onClose()
    setFormData({ name: '', email: '', source: '', feedback: '' })
  }

  return (
    <>
      <Button
        onClick={onOpen}
        variant='outline'
        justifyContent='flex-start'
        leftIcon={<Icon as={FaComment} />}
        colorScheme='orange'
        width='fit-content'
        _hover={{ bg: 'orange.200' }}
        mb = {4}
        mr = {4}
      >
        Give Us Feedback
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Leave Feedback</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel>Name</FormLabel>
              <Input
                name='name'
                value={formData.name}
                onChange={handleChange}
                placeholder='Your name'
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Email</FormLabel>
              <Input
                name='email'
                value={formData.email}
                onChange={handleChange}
                placeholder='your@email.com'
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>How did you hear about us?</FormLabel>
              <Input
                name='source'
                value={formData.source}
                onChange={handleChange}
                placeholder='e.g., Social media, friend, etc.'
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Feedback/Suggestion</FormLabel>
              <Textarea
                name='feedback'
                value={formData.feedback}
                onChange={handleChange}
                placeholder='Your feedback or suggestion'
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleSubmit} colorScheme='orange' mr={3}>
              Submit
            </Button>
            <Button variant='ghost' onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default FeedbackButton
