/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  useToast,
  IconButton,
  Tooltip
} from '@chakra-ui/react'
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import { useForm } from 'react-hook-form'

import { auth } from '../../firebase'
import { handleLoginError } from './handleLoginError'
import { InfoIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom'
interface EmailPasswordSignupFormProps {
  returnUrl?: string
}

const EmailPasswordSignupForm: React.FC<EmailPasswordSignupFormProps> = ({
  returnUrl = '/verify-email'
}) => {
  const toast = useToast()
  const navigate = useNavigate()

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    getValues
  } = useForm()

  const verifyPassword = (pw: string) => {
    const passwordRegex = /(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9]).{8,}/
    return passwordRegex.test(pw)
  }

  const onSubmit = async (values: any) => {
    if (!values.email || !values.password) {
      toast({
        title: 'Error',
        description: 'Please enter an email and password',
        status: 'error',
        duration: 5000,
        isClosable: true
      })

      return
    }

    if (!values.confirmPassword) {
      toast({
        title: 'Error',
        description: 'Please confirm your password',
        status: 'error',
        duration: 5000,
        isClosable: true
      })

      return
    }

    if (values.password !== values.confirmPassword) {
      toast({
        title: 'Error',
        description: 'Passwords do not match',
        status: 'error',
        duration: 5000,
        isClosable: true
      })

      return
    }

    if (!verifyPassword(values.password)) {
      toast({
        title: 'Error',
        description:
          'Password must be at least 8 characters long, include a number, and both uppercase and lowercase letters.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })

      return
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password
      )

      await sendEmailVerification(userCredential.user)
      navigate(returnUrl)
    } catch (error) {
      const message = handleLoginError(error)

      if (message) {
        toast({
          title: 'Error',
          description: message,
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing='6'>
        <Stack spacing='5'>
          <FormControl isInvalid={!!errors.email}>
            <FormLabel>Email</FormLabel>
            <Input
              id='email'
              type='email'
              placeholder='hello@gmail.com'
              {...register('email', {
                required: 'Please enter an email'
              })}
            />
            <FormErrorMessage>
              {!!errors.email && !!errors.email.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.password}>
            <FormLabel>
              Password
              <Tooltip label='Password must be at least 8 characters long, include a number, and both uppercase and lowercase letters.'>
                <IconButton
                  aria-label='Password requirements'
                  icon={<InfoIcon />}
                  size='xs'
                  ml={2}
                  variant='unstyled'
                />
              </Tooltip>
            </FormLabel>
            <Input
              id='password'
              type='password'
              {...register('password', {
                required: 'Please enter a password'
              })}
            />
            <FormErrorMessage>
              {!!errors.password && !!errors.password.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.confirmPassword}>
            <FormLabel>Confirm Password</FormLabel>
            <Input
              id='confirmPassword'
              type='password'
              {...register('confirmPassword', {
                required: 'Please enter a password',
                validate: (value) =>
                  value === getValues('password') || 'Passwords do not match'
              })}
            />
            <FormErrorMessage>
              {!!errors.confirmPassword && !!errors.confirmPassword.message}
            </FormErrorMessage>
          </FormControl>
        </Stack>

        <Stack spacing='6'>
          <Button isLoading={isSubmitting} type='submit'>
            Sign up
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}

export default EmailPasswordSignupForm
