import React, { useState, useEffect } from 'react'
import {
  useToast,
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  VStack
} from '@chakra-ui/react'
import { FaUpload } from 'react-icons/fa'
import axios from 'axios'
import { ref, uploadBytes } from 'firebase/storage'
import { auth, storage } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import * as amplitude from '@amplitude/analytics-browser'

import LoadingScreen from '../common/LoadingScreen'
import ErrorScreen from '../common/ErrorScreen'
import { BASE_URL } from '../../config'
import { Track, Version } from '../../_helpers/types'

interface CreateVersionProps {
  isOpen: boolean
  onClose: () => void
  trackData: Track | null
}

const CreateVersion = (props: CreateVersionProps) => {
  const [versionName, setVersionName] = useState('')
  const [file, setFile] = useState<File | null>(null)
  const [isLoadingCreateVersion, setIsLoadingCreateVersion] = useState(false)
  const [isNameCollision, setIsNameCollision] = useState(false)

  const toast = useToast()
  const [user, loading, error] = useAuthState(auth)

  useEffect(() => {
    if (versionName) {
      checkVersionNameCollision()
    }
  }, [versionName])

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = e.target.files?.[0] || null
    if (newFile) {
      setFile(newFile)
    }
  }

  const checkVersionNameCollision = async () => {
    try {
      if (props.trackData) {
        const currVersions = await axios.get(
          `${BASE_URL}/versions?trackID=${props.trackData.id}`
        )
        const currVersionNames = currVersions.data.map((version: Version) => version.name)

        if (
          currVersionNames.some(
            (name: string) =>
              name.toLowerCase().replace(/\s/g, '') ===
              versionName.toLowerCase().replace(/\s/g, '')
          )
        ) {
          setIsNameCollision(true)
          toast({
            title: 'Warning',
            description: 'You cannot have the same name as another version in the track.',
            status: 'warning',
            duration: 5000,
            isClosable: true
          })
        } else {
          setIsNameCollision(false)
        }
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'There was an error fetching version names. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  const createVersion = async () => {
    setIsLoadingCreateVersion(true)
    try {
      if (file && user && props.trackData) {
        const rand = Math.floor(100000 + Math.random() * 900000)
        const fileId = `${user?.uid}-${props.trackData.id}-${file.name}-${rand}`

        // upload file to storage bucket
        const storageRef = ref(storage, `audio/${fileId}`)
        await uploadBytes(storageRef, file)

        await axios.post(`${BASE_URL}/versions`, {
          name: versionName,
          trackID: props.trackData.id,
          fileID: fileId
        })

        amplitude.track('Version Created', {
          trackName: versionName,
          trackID: props.trackData.id,
          userID: user?.uid
        })

        toast({
          title: 'New Version Created Successfully!',
          description: 'Your version has been uploaded!',
          status: 'success',
          duration: 5000,
          isClosable: true
        })

        window.location.reload()
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'There was an error creating your version. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    } finally {
      setIsLoadingCreateVersion(false)
    }
  }

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered size='lg'>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody py={8}>
          <VStack spacing={6}>
            <Heading as='h2' size='lg'>
              Create New Version
            </Heading>
            <Box w='100%'>
              <FormControl>
                <FormLabel>Version Name</FormLabel>
                <Input
                  value={versionName}
                  onChange={(e) => setVersionName(e.target.value)}
                  placeholder='Enter version name'
                />
              </FormControl>
              <FormControl mt={4}>
                <Input
                  type='file'
                  accept='audio/*'
                  onChange={handleFileUpload}
                  hidden
                  id='file-input'
                />
                <Button
                  as='label'
                  htmlFor='file-input'
                  leftIcon={<FaUpload />}
                  variant='outline'
                  colorScheme='orange'
                  w='100%'
                >
                  {file ? file.name : 'Upload Audio'}
                </Button>
              </FormControl>
            </Box>
            <Button
              colorScheme='blue'
              isLoading={isLoadingCreateVersion}
              onClick={createVersion}
              isDisabled={!versionName || isNameCollision || !file}
              w='100%'
            >
              Create Version
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CreateVersion
