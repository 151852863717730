import React from 'react'
import {
  useToast,
  Box,
  Heading,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  VStack,
  Text,
  Flex,
  useColorModeValue
} from '@chakra-ui/react'
import { FaCheckCircle } from 'react-icons/fa'
import axios from 'axios'
import * as amplitude from '@amplitude/analytics-browser'

import { BASE_URL } from '../../config'
import { Track, Version } from '../../_helpers/types'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase'

interface MarkFinalVersionProps {
  isOpen: boolean
  onClose: () => void
  track: Track | null
  versions: Version[]
}

const MarkFinalVersion = (props: MarkFinalVersionProps) => {
  const [user] = useAuthState(auth)
  const toast = useToast()

  const bgColor = useColorModeValue('gray.50', 'gray.700')
  const textColor = useColorModeValue('gray.800', 'white')
  const buttonBgColor = useColorModeValue('orange.100', 'orange.700')
  const buttonHoverBgColor = useColorModeValue('orange.200', 'orange.600')

  const handleMarkFinalVersion = async (version: Version) => {
    try {
      if (version.isFinal) {
        await axios.put(`${BASE_URL}/versions/${version.id}`, {
          isFinal: false
        })

        amplitude.track('Final Version Unmarked', {
          versionID: version.id,
          projectID: props.track?.projectID,
          userID: user?.uid
        })

        toast({
          title: 'Final Version Unmarked',
          description: 'Your final version has been unmarked successfully!',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
      } else {
        const currentFinalVersion = props.versions.find((v) => v.isFinal)
        if (currentFinalVersion) {
          await axios.put(`${BASE_URL}/versions/${currentFinalVersion.id}`, {
            isFinal: false
          })
        }

        await axios.put(`${BASE_URL}/versions/${version.id}`, {
          isFinal: true
        })

        amplitude.track('Final Version Marked', {
          versionID: version.id,
          projectID: props.track?.projectID,
          userID: user?.uid
        })

        toast({
          title: 'Final Version Marked',
          description: 'Your final version has been marked successfully!',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
      }
      window.location.reload()
    } catch (error) {
      toast({
        title: 'Error',
        description: `There was an error marking the final version. Please try again.`,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  const handleClose = () => {
    props.onClose()
  }

  return (
    <Modal isOpen={props.isOpen} onClose={handleClose} isCentered size='lg'>
      <ModalOverlay />
      <ModalContent bg={bgColor}>
        <ModalHeader>
          <Heading as='h2' size='lg' color={textColor}>
            Mark Final Version
          </Heading>
        </ModalHeader>
        <ModalBody>
          <Box p={6} borderRadius='lg' boxShadow='md'>
            <Heading as='h3' size='md' mb={4} color={textColor}>
              {props.track?.name}
            </Heading>
            <Text mb={4} color={textColor}>
              Select a version to mark as final:
            </Text>
            <VStack spacing={3} align='stretch'>
              {(props.versions || [])
                .sort(
                  (a, b) =>
                    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                )
                .map((version) => (
                  <Flex
                    key={version.id}
                    justify='space-between'
                    align='center'
                    bg={version.isFinal ? 'green.100' : buttonBgColor}
                    p={3}
                    borderRadius='md'
                    boxShadow='sm'
                    _hover={{
                      boxShadow: 'md',
                      bg: version.isFinal ? 'green.200' : buttonHoverBgColor
                    }}
                    transition='all 0.2s'
                  >
                    <Text fontWeight='medium' color={textColor}>
                      {version.name}
                    </Text>
                    <Button
                      onClick={() => handleMarkFinalVersion(version)}
                      colorScheme={version.isFinal ? 'red' : 'orange'}
                      size='sm'
                      leftIcon={version.isFinal ? <FaCheckCircle /> : undefined}
                    >
                      {version.isFinal ? 'Unmark' : 'Mark as Final'}
                    </Button>
                  </Flex>
                ))}
            </VStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default MarkFinalVersion
