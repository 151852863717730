import React, { useState, useEffect, useCallback } from 'react'
import {
  Box,
  VStack,
  HStack,
  Heading,
  Text,
  Button,
  IconButton,
  useColorModeValue,
  Container,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { FaPlay, FaEllipsisV, FaTrash, FaEdit } from 'react-icons/fa'
import { Permission, Project, Track } from '../../_helpers/types'
import { BASE_URL } from '../../config'
import LoadingScreen from '../common/LoadingScreen'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import CreateTrack from './CreateTrack'
import TrackDetails from './TrackDetails'
import ShareClient from '../ShareClient'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase'
import { getPermissions, Role } from '../../api/permission/permissions'

const ProjectTracks: React.FC<{ setShowSidebar: (show: boolean) => void }> = ({
  setShowSidebar
}) => {
  const [user] = useAuthState(auth)
  const [permissions, setPermissions] = useState<Permission[]>([])

  const { projectID } = useParams()
  const navigate = useNavigate()
  const toast = useToast()
  const [projectData, setProjectData] = useState<Project | null>(null)
  const [tracksData, setTracksData] = useState<Track[]>([])
  const [track, setTrack] = useState<Track | null>(null)
  const {
    isOpen: isTrackDetailsOpen,
    onOpen: onOpenTrackDetails,
    onClose: onCloseTrackDetails
  } = useDisclosure()

  const {
    isOpen: isCreateTrackOpen,
    onOpen: onOpenCreateTrack,
    onClose: onCloseCreateTrack
  } = useDisclosure()

  useEffect(() => {
    setShowSidebar(true)
  }, [])

  useEffect(() => {
    const fetchProjectData = async () => {
      const projectResponse = await axios.get(`${BASE_URL}/projects/${projectID}`)
      setProjectData(projectResponse.data)
    }

    fetchProjectData()
  }, [projectID])

  const fetchTracksData = async () => {
    const tracksResponse = await axios.get(`${BASE_URL}/tracks?projectID=${projectID}`)
    setTracksData(tracksResponse.data)
  }

  const fetchPermissions = useCallback(async () => {
    if (!projectID || !user?.uid) return
    const permissions = await getPermissions(projectID, user?.uid)
    setPermissions(permissions || [])
  }, [projectID, user?.uid])

  useEffect(() => {
    fetchTracksData()
  }, [projectID])

  useEffect(() => {
    fetchPermissions()
  }, [fetchPermissions])

  const bgColor = useColorModeValue('beige', 'gray.900')
  const textColor = useColorModeValue('black', 'white')
  const secondaryTextColor = useColorModeValue('gray.600', 'gray.400')
  const trackBgColor = useColorModeValue('orange.100', 'gray.800')
  const trackHoverBgColor = useColorModeValue('orange.200', 'gray.700')

  // const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0]
  //   if (file) {
  //     const formData = new FormData()
  //     formData.append('file', file)

  //     try {
  //       await axios.post(`${BASE_URL}/tracks`, formData, {
  //         headers: {
  //           'Content-Type': 'multipart/form-data'
  //         }
  //       })

  //       fetchTracksData()
  //     } catch (error) {
  //       console.error('Error uploading file:', error)
  //     }
  //   }
  // }

  const handleDeleteTrack = async (trackID: string) => {
    await axios.delete(`${BASE_URL}/tracks/${trackID}`)
    fetchTracksData()

    toast({
      title: 'Track deleted',
      status: 'success',
      duration: 3000,
      isClosable: true
    })
  }

  if (!projectData) return <LoadingScreen />

  const isOwner =
    permissions.some((permission) => permission.roles.includes(Role.MANAGE)) ||
    projectData.userIDs.includes(user?.uid || '') ||
    projectData.userID === user?.uid

  const canCreateTrack = permissions.some(
    (permission) =>
      permission.roles.includes(Role.UPLOAD) || permission.roles.includes(Role.MANAGE)
  )

  return (
    <>
      <Box minH='100vh' bg={bgColor} color={textColor} px={6}>
        <Container maxW='container.xl' py={8}>
          <VStack spacing={8} align='stretch'>
            <Flex justifyContent='space-between' alignItems='center'>
              <VStack align='flex-start' spacing={1}>
                <Heading size='2xl'>{projectData.name}</Heading>
                {/* <Text color={secondaryTextColor}> */}
                {/* TODO: Add project description field in DB
                  {projectData.description || 'Project description'}
                */}
                {/* </Text> */}
              </VStack>
              {/* <HStack>
                <IconButton
                  icon={<FaPlay />}
                  aria-label='Play all'
                  colorScheme='orange'
                  variant='outline'
                  rounded='full'
                />
                <IconButton
                  icon={<FaDownload />}
                  aria-label='Download'
                  bg={buttonBgColor}
                  color='white'
                  rounded='full'
                  colorScheme='orange'
                />
              </HStack> */}
            </Flex>

            <HStack display='flex' justifyContent='space-between'>
              {/* <input
                type='file'
                accept='audio/*'
                onChange={handleFileUpload}
                style={{ display: 'none' }}
                id='file-upload'
              />
              <label htmlFor='file-upload'>
                <Button
                  as='span'
                  leftIcon={<FaUpload />}
                  bg={buttonBgColor}
                  color='white'
                  colorScheme='orange'
                  cursor='pointer'
                >
                  Upload
                </Button>
              </label> */}
              <Box>
                {(canCreateTrack || isOwner) && (
                  <Menu>
                    <MenuButton as={Button} variant='outline' colorScheme='orange'>
                      Create
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={onOpenCreateTrack}>New Track</MenuItem>
                    </MenuList>
                  </Menu>
                )}
              </Box>
              <HStack spacing={4} justifyContent='flex-end'>
                {isOwner && (
                  <ShareClient
                    projectName={projectData.name}
                    projectID={projectID || ''}
                    isOwner={projectData.userIDs.includes(user?.uid || '')}
                  />
                )}
                {/** TODO: Implement download button - need to change up backend schema for this */}
                {/* <IconButton
                  as={Button}
                  icon={<FaDownload />}
                  aria-label='Download'
                  colorScheme='orange'
                  borderRadius='full'
                /> */}
              </HStack>
              {/* <Button variant='outline'>Add from Library</Button> */}
            </HStack>

            <VStack spacing={4} align='stretch'>
              {Array.isArray(tracksData) &&
                tracksData.map((track: Track) => (
                  <Box
                    key={track.id}
                    bg={trackBgColor}
                    p={4}
                    borderRadius='md'
                    _hover={{ bg: trackHoverBgColor }}
                    // Need to figure out how to get the default version of the track
                    onClick={() => navigate(`/projects/${projectID}/${track.id}`)}
                  >
                    <HStack justifyContent='space-between'>
                      <HStack>
                        <IconButton
                          icon={<FaPlay />}
                          aria-label={`Play ${track.name}`}
                          variant='ghost'
                          color='green.500'
                          borderRadius='full'
                        />
                        <VStack align='flex-start' spacing={0}>
                          <Text fontWeight='bold'>{track.name}</Text>
                          <Text fontSize='sm' color={secondaryTextColor}>
                            {/* TODO: Add track versions field in DB
                            TRACK • {track.versions?.length || 0} Versions
                            */}
                            TRACK
                          </Text>
                        </VStack>
                      </HStack>
                      <HStack>
                        {/* TODO: Add track duration field in DB
                        <Text>{track.duration || '0:00'}</Text>
                        */}
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            icon={<FaEllipsisV />}
                            variant='ghost'
                            aria-label='Track options'
                            borderRadius='full'
                            _hover={{ bg: 'orange.400' }}
                            _active={{ bg: trackHoverBgColor }}
                            onClick={(e) => {
                              e.stopPropagation()
                            }}
                          />
                          <MenuList>
                            <MenuItem
                              isDisabled={!isOwner}
                              icon={<FaEdit />}
                              onClick={(e) => {
                                e.stopPropagation()
                                setTrack(track)
                                onOpenTrackDetails()
                              }}
                            >
                              Edit Track
                            </MenuItem>
                            <MenuItem
                              isDisabled={!isOwner}
                              icon={<FaTrash />}
                              onClick={(e) => {
                                e.stopPropagation()
                                handleDeleteTrack(track.id)
                              }}
                            >
                              Delete Track
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </HStack>
                    </HStack>
                  </Box>
                ))}
            </VStack>
          </VStack>
        </Container>
      </Box>
      <CreateTrack
        isOpen={isCreateTrackOpen}
        onClose={onCloseCreateTrack}
        project={projectData}
      />
      <TrackDetails
        isOpen={track !== null && isTrackDetailsOpen}
        onClose={onCloseTrackDetails}
        track={track!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
        fetchTracksData={fetchTracksData}
      />
    </>
  )
}

export default ProjectTracks
