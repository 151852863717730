import {
  addDoc,
  deleteDoc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where
} from 'firebase/firestore'
import { permissionsDB } from '../../firebase'
import { Permission } from '../../_helpers/types'

enum Role {
  COMMENT = 'comment',
  DOWNLOAD = 'download',
  UPLOAD = 'upload',
  MANAGE = 'manage'
}

async function getPermissions(projectID: string, email?: string): Promise<Permission[]> {
  let q
  if (email) {
    q = query(
      permissionsDB,
      where('projectID', '==', projectID),
      where('email', '==', email)
    )
  } else {
    q = query(permissionsDB, where('projectID', '==', projectID))
  }

  try {
    const docSnap = await getDocs(q)
    const data = docSnap.docs.map((doc) => doc.data())
    return data as Permission[]
  } catch (e) {
    console.error('Error getting permissions: ', e)
    return []
  }
}

async function createPermissions(
  permission: Permission
): Promise<Permission | undefined> {
  try {
    const docRef = await addDoc(permissionsDB, permission)
    const document = (await getDoc(docRef)).data()
    return document as Permission
  } catch (e) {
    console.error('Error adding permissions: ', e)
  }
}

async function updatePermissions(permission: Permission): Promise<void> {
  const q = query(
    permissionsDB,
    where('projectID', '==', permission.projectID),
    where('email', '==', permission.email)
  )

  const docSnap = await getDocs(q)
  if (docSnap.empty) {
    createPermissions(permission)
    return
  }

  docSnap.docs.forEach((doc) => {
    updateDoc(doc.ref, { ...permission })
  })
}

async function deletePermissions(projectID: string, email: string): Promise<void> {
  const q = query(
    permissionsDB,
    where('projectID', '==', projectID),
    where('email', '==', email)
  )

  getDocs(q).then((docSnap) => {
    docSnap.docs.forEach((doc) => {
      deleteDoc(doc.ref)
    })
  })
}

export { Role, getPermissions, updatePermissions, deletePermissions }
