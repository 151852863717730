import React from 'react'
import {
  Heading,
  Stack,
  Container,
  Divider,
  HStack,
  Text,
  ButtonGroup,
  Flex,
  Box,
  Image
} from '@chakra-ui/react'
import GoogleProvider from './GoogleProvider'
// import FacebookProvider from './FacebookProvider'

interface Props {
  headingText: string
  emailPasswordComponent: React.JSX.Element
  helpComponent: React.JSX.Element
  returnUrl?: string
  shouldSave: boolean
}

const RegistrationPageTemplate: React.FC<Props> = (props) => {
  return (
    <Flex minHeight='100vh' height='100vh'>
      {/* Left side - Image */}
      <Box width={{ base: '100%', md: '75%%' }} height='100%'>
        <Image
          src='/login.jpg'
          alt='Login visual'
          objectFit='cover'
          w='max-content'
          h='100%'
        />
      </Box>

      {/* Right side - Login form */}
      <Flex
        width={{ base: '100%', md: '50%' }}
        height='100%'
        alignItems='center'
        justifyContent='center'
        bg='orange.50'
        // overflow="auto"
      >
        <Container minW='xl' py={8}>
          <Stack spacing='6' alignItems='center'>
            <Image src='/logo.png' alt='Logo' width='70%' />
            <Stack spacing='6' textAlign='center'>
              <Heading size='xl'>{props.headingText}</Heading>
            </Stack>

            <ButtonGroup
              variant='outline'
              spacing={4}
              width='full'
              justifyContent='center'
            >
              <GoogleProvider returnUrl={props.returnUrl} shouldSave={props.shouldSave} />
              {/* <FacebookProvider /> */}
            </ButtonGroup>

            <HStack>
              <Divider />
              <Text fontSize='sm' whiteSpace='nowrap' color='gray.500'>
                or
              </Text>
              <Divider />
            </HStack>

            {/* {props.emailPasswordComponent} */}

            <Box mt={4}>{props.helpComponent}</Box>
          </Stack>
        </Container>
      </Flex>
    </Flex>
  )
}

export default RegistrationPageTemplate
