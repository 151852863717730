import React from 'react'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  Input,
  VStack,
  Text
} from '@chakra-ui/react'

import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase'
import { Version } from '../../_helpers/types'
import LoadingScreen from '../common/LoadingScreen'
import ErrorScreen from '../common/ErrorScreen'
import axios from 'axios'
import { BASE_URL } from '../../config'

interface RenameVersionProps {
  version: Version
  isOpen: boolean
  onClose: () => void
  // fetchVersions: () => void
}

const RenameVersion = ({
  version,
  isOpen,
  onClose,
  // fetchVersions
}: RenameVersionProps) => {
  const toast = useToast()
  const [newVersionName, setNewVersionName] = React.useState<string>('')
  const [user, loading, error] = useAuthState(auth)

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  const handleRenameVersion = async () => {
    try {
      if (user && version) {
        const versionID = version.id

        await axios.put(`${BASE_URL}/versions/${versionID}`, {
          name: newVersionName,
          trackID: version.trackID,
          fileID: version.fileID
        })
      }

      onClose()

      // TODO: Make this a refetch instead of a reload
      window.location.reload()
      // fetchVersions()
    } catch (error) {
      toast({
        title: 'Error renaming version',
        description: 'Error renaming the version. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderColor='orange.200' borderWidth={1}>
        <ModalHeader color='orange.800'>Rename Version</ModalHeader>
        <ModalCloseButton color='orange.800' />
        <ModalBody>
          <VStack spacing={4} align='stretch'>
            <Text color='orange.800'>Enter a new name for the version:</Text>
            <Input
              type='text'
              value={newVersionName}
              onChange={(e) => setNewVersionName(e.target.value)}
              size='md'
              variant='filled'
              bg='beige'
              _hover={{ bg: 'orange.100' }}
              _focus={{ bg: 'orange.100', borderColor: 'orange.300' }}
              color='orange.800'
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            bg='orange.400'
            color='white'
            _hover={{ bg: 'orange.500' }}
            onClick={handleRenameVersion}
            isDisabled={newVersionName === '' || newVersionName.replace(/\s/g, '') === ''}
            size='md'
          >
            Rename
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default RenameVersion
