import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  VStack,
  Input,
  Button,
  Text
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

interface EmailModalProps {
  isOpen: boolean
  onClose: () => void
  setHasProvidedEmailOrSignedIn: React.Dispatch<React.SetStateAction<boolean>>
}

const EmailModal: React.FC<EmailModalProps> = ({
  isOpen,
  onClose,
  setHasProvidedEmailOrSignedIn
}) => {
  const [email, setEmail] = useState('')
  const navigate = useNavigate()
  const { projectID, trackID } = useParams<{
    projectID: string
    trackID: string
  }>()

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (email) {
      localStorage.setItem('userEmail', email)
      setHasProvidedEmailOrSignedIn(true)
      onClose()
    }
  }

  const handleSignIn = () => {
    const currentPath = `/projects/${projectID}/${trackID}`
    navigate(`/login?returnUrl=${encodeURIComponent(currentPath)}`)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Welcome</ModalHeader>
        <ModalBody>
          <VStack spacing={4}>
            <Text>Please enter your email or sign in to continue:</Text>
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <Input
                placeholder='Enter your email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                mb={4}
              />
              <Button type='submit' colorScheme='orange' width='100%'>
                Continue with Email
              </Button>
            </form>
            <Text>or</Text>
            <Button onClick={handleSignIn} colorScheme='blue' width='100%'>
              Sign In
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default EmailModal
