import React from 'react'
import { motion } from 'framer-motion'
import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  Container,
  useColorModeValue
} from '@chakra-ui/react'

interface FeatureSectionProps {
  title: string
  description: string
  icon: string
  isReversed: boolean
}

const FeatureSection = ({
  title,
  description,
  icon,
  isReversed
}: FeatureSectionProps) => {
  const bgColor = useColorModeValue('gray.50', 'gray.800')
  const textColor = useColorModeValue('gray.800', 'white')

  return (
    <Box bg={isReversed ? 'orange.100' : bgColor} py={{ base: 12, md: 20 }}>
      <Container maxW='container.xl'>
        <Flex
          direction={{ base: 'column', md: isReversed ? 'row-reverse' : 'row' }}
          align='center'
          justify='space-between'
        >
          <Box flex={1} mb={{ base: 10, md: 0 }}>
            <motion.div
              initial={{ opacity: 0, x: isReversed ? 50 : -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              <Flex justifyContent='space-between' flexDirection='column'>
                <Heading as='h1' size='2xl' mb={4} color={textColor}>
                  {title}
                </Heading>
                <Text fontSize='2xl' color={textColor}>
                  {description}
                </Text>
              </Flex>
            </motion.div>
          </Box>
          <Flex flex={1} justify='center' align='center'>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              <Image src={icon} />
            </motion.div>
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

const FeaturesSection = () => {
  const features = [
    {
      title: 'AI-Powered Insights',
      description:
        'Our AI bridges the gap between you and your non-technical clients, reducing the back and forth and letting you spend time creating, not explaining.',
      icon: '1.png'
    },
    {
      title: 'Centralized Project Hub',
      description:
        'Unite your files, tasks, and communications in one intuitive space. Say goodbye to scattered information and communication.',
      icon: '3.png'
    },
    {
      title: 'Clear and Direct Communcation',
      description:
        'Specify exactly what changes you want with timestamped comments and organized feedback. Never go searching for that one comment on that one track again.',
      icon: '2.png'
    }
  ]

  const bgColor = useColorModeValue('gray.50', 'gray.800')
  const textColor = useColorModeValue('gray.800', 'white')

  return (
    <Box as='section' id='features' bg={bgColor}>
      <Container maxW='container.xl' pt={20}>
        <Heading as='h2' size='2xl' textAlign='center' color={textColor} fontSize='60px'>
          The best way to share and discuss audio projects
        </Heading>
        {/* <Flex
          bg='gray.200'
          px={4}
          borderRadius='full'
          alignItems='center'
          maxWidth='fit-content'
          mx='auto'
          mt={3}
          py={1}
        >
          <Text fontSize='sm' fontWeight='medium' mr={4}>
            An audio-first alternative to
          </Text>
          <Flex alignItems='center' gap={3}>
            <Image src='/api/placeholder/24/24' alt='WeTransfer' />
            <Text fontWeight='bold'>WeTransfer</Text>
            <Image src='/api/placeholder/24/24' alt='Dropbox' />
            <Text fontWeight='bold'>Dropbox</Text>
            <Image src='/api/placeholder/24/24' alt='Google Drive' />
            <Text fontWeight='bold'>Drive</Text>
          </Flex>
        </Flex> */}
      </Container>
      <Flex direction="column" gap={{ base: 8, md: 0 }}>
        {features.map((feature, index) => (
          <FeatureSection key={index} {...feature} isReversed={index % 2 !== 0} />
        ))}
      </Flex>
    </Box>
  )
}

export default FeaturesSection
