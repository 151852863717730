import React, { useEffect } from 'react'
import { Box, Flex, Text, Button, useDisclosure, Icon, HStack } from '@chakra-ui/react'
import { FaFolder, FaPlus } from 'react-icons/fa'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase'
import { useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga4'

import LoadingScreen from '../common/LoadingScreen'
import ErrorScreen from '../common/ErrorScreen'
import { Project } from '../../_helpers/types'
import CreateProject from './CreateProject'
import { BASE_URL } from '../../config'
import axios from 'axios'

const ProjectsHome: React.FC<{ setShowSidebar: (show: boolean) => void }> = ({
  setShowSidebar
}) => {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
    name: 'Projects Home'
  })

  const [user, loading, error] = useAuthState(auth)
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [projectsData, setProjectsData] = React.useState<Project[]>([])
  const [projectsLoading, setProjectsLoading] = React.useState(false)
  const [projectsError, setProjectsError] = React.useState<Error | null>(null)
  const [filter, setFilter] = React.useState(false)
  const [filterStatus, setFilterStatus] = React.useState(false)

  useEffect(() => {
    setShowSidebar(true)
  }, [])

  useEffect(() => {
    if (!error && user?.uid) {
      setProjectsLoading(true)
      axios
        .get(`${BASE_URL}/projects?userID=${user.uid}`)
        .then((response: { data: React.SetStateAction<Project[]> }) => {
          setProjectsData(response.data)
          setProjectsLoading(false)
        })
        .catch((error) => {
          setProjectsError(error)
          setProjectsLoading(false)
        })
    }
  }, [user?.uid])

  if (loading || projectsLoading) {
    return <LoadingScreen />
  }

  if (!user) {
    const currentPath = `/projects`
    navigate(`/login?returnUrl=${encodeURIComponent(currentPath)}`)
  }

  if (error || projectsError) {
    return <ErrorScreen error={(error || projectsError) as Error} />
  }

  const handleFilter = (newFilter: boolean) => {
    if (filterStatus == false) {
      setFilterStatus(true)
      setFilter(newFilter)
    } else if (filterStatus == true) {
      if (filter == newFilter) {
        setFilterStatus(false)
      } else {
        setFilter(newFilter)
      }
    }
  }

  return (
    <Flex>
      <Box bg='beige' minHeight='100vh' color='black' flex='1'>
        <Flex direction='column' margin='0 auto' p={8}>
          <Flex justify='space-between' align='center' mb={6}>
            <Flex align='center'>
              <Text fontSize='2xl' fontWeight='bold'>
                Library
              </Text>
            </Flex>
          </Flex>

          {/* TODO: Add more informative views here based on customer feedback */}
          <Flex mb={6}>
            {/* <Button variant='ghost' color='black' mr={4}>
              Home
            </Button>
            <Button variant='ghost' color='gray.600' mr={4}>
              Spaces
            </Button>
            <Button variant='ghost' color='gray.600'>
              Tracks
            </Button> */}
          </Flex>

          <Flex justifyContent='space-between'>
            <Flex>
              <Button colorScheme='orange' mr={2} onClick={onOpen}>
                Create
              </Button>
              {/* <Button colorScheme='orange' variant='outline'>
              Upload
            </Button> */}
            </Flex>
            <HStack mb={4} justifyContent={'flex-end'}>
              <Text>Filter By:</Text>
              <Button
                bgColor={!filter && filterStatus ? 'red.400' : 'orange.100'}
                borderRadius='md'
                _hover={{
                  bg: !filter && filterStatus ? '' : 'orange.200',
                  cursor: 'pointer'
                }}
                onClick={() => handleFilter(false)}
              >
                Active
              </Button>
              <Button
                bgColor={filter && filterStatus ? 'green.500' : 'orange.100'}
                borderRadius='md'
                _hover={{
                  bg: filter && filterStatus ? '' : 'orange.200',
                  cursor: 'pointer'
                }}
                onClick={() => handleFilter(true)}
              >
                Completed
              </Button>
            </HStack>
          </Flex>

          {projectsData &&
            projectsData
              .filter((project: Project) => {
                if (filterStatus) {
                  return filter ? project.isComplete : !project.isComplete
                } else {
                  return true
                }
              })
              .sort((a: Project, b: Project) => {
                // Assuming your Project interface has a 'date' property
                const dateA = new Date(a.createdAt)
                const dateB = new Date(b.createdAt)
                return dateA.getTime() - dateB.getTime()
              })
              .reverse()
              .map((project: Project) => (
                <Flex
                  key={project.id}
                  bg='orange.100'
                  p={4}
                  borderRadius='md'
                  mb={4}
                  align='center'
                  _hover={{ bg: 'orange.200', cursor: 'pointer' }}
                  onClick={() => navigate(`/projects/${project.id}`)}
                >
                  {/* <Image
                  src='/api/placeholder/48/48'
                  alt='Project thumbnail'
                  boxSize='48px'
                  borderRadius='md'
                  mr={4}
                /> */}
                  <Icon as={FaFolder} boxSize={4} mr={4} />
                  <Box width='100%'>
                    <HStack justifyContent={'space-between'}>
                      <Text fontWeight='bold'>{project.name}</Text>

                      <Box p={1} rounded={4}>
                        <Text
                          color={project.isComplete ? 'green.500' : 'red.400'}
                          fontSize='sm'
                          textAlign={'center'}
                          fontWeight={'bold'}
                        >
                          {project.isComplete ? 'Completed' : 'Active'}
                        </Text>
                      </Box>
                    </HStack>
                    <Text color='gray.600' fontSize='sm' mt={2}>
                      Created on {project.createdAt.split('T')[0]} by{' '}
                      {user?.email == project.createdBy ? 'you' : project.createdBy}
                    </Text>
                  </Box>
                </Flex>
              ))}

          <HStack justifyContent={'space-between'}>
            <Button
              leftIcon={<FaPlus />}
              colorScheme='orange'
              variant='ghost'
              onClick={onOpen}
              alignSelf='flex-start'
            >
              New Project
            </Button>
            <Text color='gray.600'>
              {projectsData
                ? filterStatus
                  ? projectsData.filter((project: Project) =>
                      filter ? project.isComplete : !project.isComplete
                    ).length
                  : projectsData.length
                : 0}{' '}
              Results
            </Text>
          </HStack>
        </Flex>

        <CreateProject isOpen={isOpen} onClose={onClose} />
      </Box>
    </Flex>
  )
}

export default ProjectsHome
