/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  useToast
} from '@chakra-ui/react'
import { signInWithEmailAndPassword } from 'firebase/auth'
import React from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { auth } from '../../firebase'
import { handleLoginError } from './handleLoginError'

interface EmailPasswordLoginFormProps {
  returnUrl?: string
}

const EmailPasswordLoginForm: React.FC<EmailPasswordLoginFormProps> = ({
  returnUrl
}) => {
  const navigate = useNavigate()
  const toast = useToast()

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting }
  } = useForm()

  const onSubmit = async (values: any) => {
    try {
      await signInWithEmailAndPassword(auth, values.email, values.password)
      navigate(returnUrl || '/projects')
    } catch (error) {
      const message = handleLoginError(error)

      if (message) {
        toast({
          title: 'Error',
          description: message,
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing='6'>
        <Stack spacing='5'>
          <FormControl isInvalid={!!errors.email}>
            <FormLabel>Email</FormLabel>
            <Input
              id='email'
              type='email'
              placeholder='hello@gmail.com'
              {...register('email', {
                required: 'Please enter an email'
              })}
            />
            <FormErrorMessage>
              {!!errors.email && !!errors.email.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.password}>
            <FormLabel>Password</FormLabel>
            <Input
              id='password'
              type='password'
              {...register('password', {
                required: 'Please enter a password'
              })}
            />
            <FormErrorMessage>
              {!!errors.password && !!errors.password.message}
            </FormErrorMessage>
          </FormControl>
        </Stack>

        <Stack spacing='6'>
          <Button isLoading={isSubmitting} type='submit'>
            Sign in
          </Button>
        </Stack>
        <Link to='/forgot-password'>
          <Button variant='link' colorScheme='blue' size='sm' alignSelf='flex-start'>
            Forgot password?
          </Button>
        </Link>
      </Stack>
    </form>
  )
}

export default EmailPasswordLoginForm
