import React from 'react'
import { motion } from 'framer-motion'
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Stack,
  Image,
  Container
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { TypeAnimation } from 'react-type-animation'

const HeroSection = () => {
  const navigate = useNavigate()
  return (
    <Box
      minHeight='100vh'
      bg='orange.100'
      color='white'
      position='relative'
      overflow='hidden'
    >
      <Container maxW='container.xl' position='relative' zIndex={2}>
        <Flex
          direction='column'
          align='center'
          justify='center'
          minHeight='100vh'
          textAlign='center'
        >
          <Box mb={4}>
            {/* <Text fontSize='sm' fontWeight='bold'>
              A techstars_ portfolio company
            </Text> */}
          </Box>
          <Heading as='h1' size='4xl' mb={4}>
            Google Docs
            <br />
            For Audio.
          </Heading>
          <Box
            fontSize='xl'
            mb={8}
            // height='40px'
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <TypeAnimation
              preRenderFirstString={true}
              sequence={[
                500,
                'Clear Feedback ',
                1000,
                'Version Control',
                1000,
                'Task Management',
                1000,
                'Sharing Audio Files',
                500
              ]}
              wrapper='span'
              speed={50}
              cursor={false}
              style={{ display: 'inline-block', fontSize: '1em' }}
              repeat={Infinity}
            />
            <Text ml={2} fontSize='1em'>
              has never been easier
            </Text>
          </Box>
          <Stack direction='row' spacing={4}>
            <Button
              as={motion.button}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              size='lg'
              colorScheme='brand'
              onClick={() => navigate('/login')}
            >
              Get Started
            </Button>
            <Button
              as={motion.button}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              size='lg'
              bg='orange.50'
              borderColor='white'
              onClick={() =>
                (window.location.href = 'https://calendly.com/hello-soloflow')
              }
            >
              Book a Demo
            </Button>
          </Stack>
        </Flex>
      </Container>
      <Box
        position='absolute'
        bottom='-10%'
        left='50%'
        transform='translateX(-50%)'
        width='80%'
        zIndex={1}
      >
        <Image src='/app-screenshot.png' alt='Soloflow app interface' />
      </Box>
      <motion.div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'linear-gradient(45deg, #FFA500, #F4A460, #D2691E)',
          backgroundSize: '400% 400%',
          zIndex: 0
        }}
        animate={{
          backgroundPosition: ['0% 50%', '100% 50%', '0% 50%']
        }}
        transition={{
          duration: 10,
          ease: 'easeInOut',
          repeat: Infinity
        }}
      />
      {[...Array(5)].map((_, index) => (
        <motion.div
          key={index}
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            height: '2px',
            background: 'rgba(255, 255, 255, 0.3)',
            zIndex: 1
          }}
          animate={{
            top: ['100%', '0%'],
            opacity: [0, 1, 0]
          }}
          transition={{
            duration: 5,
            ease: 'linear',
            repeat: Infinity,
            delay: index * 0.5
          }}
        />
      ))}
    </Box>
  )
}

export default HeroSection
