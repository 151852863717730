import React, { useState, useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import {
  ChakraProvider,
  extendTheme,
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Stack,
  Input,
  Textarea,
  useToast,
  AspectRatio,
  HStack,
  Link,
  Image,
} from '@chakra-ui/react'
import FeaturesSection from './home/FeaturesSection'
import HeroSection from './home/HeroSection'
import { useNavigate } from 'react-router-dom'
import Footer from './common/Footer'
import emailjs from '@emailjs/browser'

// Define types for our theme
type ThemeColors = {
  [key: string]: {
    [key: number]: string
  }
}

const themeColors: ThemeColors = {
  brand: {
    50: '#FFF5E6',
    100: '#FFE0B2',
    200: '#FFCC80',
    300: '#FFB74D',
    400: '#FFA726',
    500: '#FF9800',
    600: '#FB8C00',
    700: '#F57C00',
    800: '#EF6C00',
    900: '#E65100'
  }
}

const theme = extendTheme({
  colors: themeColors,
  fonts: {
    heading: '"Poppins", sans-serif',
    body: '"Inter", sans-serif'
  }
})

interface Testimonial {
  name: string
  role: string
  quote: string
  avatar: string
}

interface LandingPageProps {
  setShowSidebar: (showSidebar: boolean) => void
}

const LandingPage: React.FC<LandingPageProps> = ({ setShowSidebar }) => {
  const [currentWord, setCurrentWord] = useState<number>(0)
  const navigate = useNavigate()

  const words: string[] = ['Collaborate', 'Create', 'Innovate', 'Succeed']
  const controls = useAnimation()
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  })

  useEffect(() => {
    setShowSidebar(false)
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWord((prev) => (prev + 1) % words.length)
    }, 2000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    controls.start({
      opacity: [0, 1],
      y: [20, 0],
      transition: { duration: 0.5 }
    })
  }, [currentWord, controls])

  const toast = useToast()

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const templateParams = {
      name: formData.name,
      email: formData.email,
      source: 'Landing Page',
      feedback: formData.message
    }
    // TODO: setup backend api endpoint to send email
    const serviceID = 'service_5kz2csq'
    const templateID = 'template_h40uzem'
    const userPublicKey = 'Th0mry8sH3us0Uv05'
    emailjs.send(serviceID, templateID, templateParams, {
      publicKey: userPublicKey
    })

    toast({
      title: 'Feedback sent successfully',
      description: 'Thank you for your feedback!',
      status: 'success',
      duration: 5000,
      isClosable: true
    })
    setFormData({ name: '', email: '', message: '' })
  }

  return (
    <ChakraProvider theme={theme}>
      <Box>
        {/* Navbar */}
        <Box as='nav' bg='white' boxShadow='sm' position='sticky' top={0} zIndex={10}>
          <Flex
            mx='auto'
            px={4}
            align='center'
            justify='space-between'
          >
            <Flex align='center'>
              {/* <Heading as="h1" size="lg" letterSpacing={'tighter'}>
                Soloflow
              </Heading> */}
              <Image src='/logo.png' alt='Soloflow Logo' height='60px'/>
            </Flex>
            <HStack spacing={8} display={{ base: 'none', md: 'flex' }}>
              <Link href='#features'>Features</Link>
              <Link href='#demo'>Demo</Link>
              <Link href='#testimonials'>Testimonials</Link>
              <Link href='#contact'>Contact</Link>
            </HStack>
            <HStack>
              <Button variant='ghost' onClick={() => navigate('/login')}>
                Log in
              </Button>
              <Button colorScheme='brand' onClick={() => navigate('/signup')}>
                Sign up
              </Button>
            </HStack>
          </Flex>
        </Box>

        {/* Hero Section */}
        <HeroSection />

        {/* Features Section */}
        <FeaturesSection />

        {/* Interactive Demo Section */}
        <Box py={20} id='demo'>
          <Heading as='h2' size='2xl' textAlign='center' mb={16} color='gray.800'>
            Experience Soloflow in Action
          </Heading>
          <Box maxW='1000px' mx='auto'>
            <Box
              alignItems='center'
              justifyContent='center'
              bg='gray.800'
              color='white'
              position='relative'
              overflow='hidden'
            >
              <AspectRatio ratio={16 / 9}>
                <iframe
                  src='https://www.youtube.com/embed/LQ0QtYrfz-Q'
                  title='YouTube video player'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                />
              </AspectRatio>
            </Box>
          </Box>
        </Box>

        {/* Testimonials Section */}
        <Box py={20} bg='brand.100' id='testimonials'>
          <Heading as='h2' size='2xl' textAlign='center' mb={16} color='gray.800'>
            What Our Users Say
          </Heading>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            justify='center'
            align='center'
            maxW='1200px'
            mx='auto'
            gap={8}
          >
            {[
              {
                name: 'Alex Johnson',
                role: 'Music Producer',
                quote:
                  "Soloflow has completely transformed how I collaborate with artists. It's a game-changer!",
                avatar: '/alex_johnson.png'
              },
              {
                name: 'Sarah Lee',
                role: 'Sound Engineer',
                quote:
                  'The seamless communication and feedback system in Soloflow has cut our revision time in half.',
                avatar: '/sarah_lee.png'
              },
              {
                name: 'Alejandro Flores',
                role: 'Indie Musician',
                quote:
                  'As an independent artist, Soloflow has made it possible for me to work with professionals around the world effortlessly.',
                avatar: '/alejandro_flores.png'
              }
            ].map((testimonial: Testimonial, index: number) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.8 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
              >
                <Box
                  p={8}
                  borderRadius='xl'
                  boxShadow='xl'
                  maxW='300px'
                  h='100%'
                  bg='white'
                >
                  <Text fontSize='md' fontStyle='italic' mb={4}>
                    {`${testimonial.quote}`}
                  </Text>
                  <Text fontWeight='bold'>{testimonial.name}</Text>
                  <Text fontSize='sm' color='gray.500'>
                    {testimonial.role}
                  </Text>
                </Box>
              </motion.div>
            ))}
          </Flex>
        </Box>

        {/* CTA Section */}
        <Box py={20} bg='brand.500' color='white'>
          <Flex
            direction='column'
            align='center'
            maxW='800px'
            mx='auto'
            textAlign='center'
          >
            <Heading as='h2' size='2xl' mb={8}>
              Ready to Elevate Your Audio Projects?
            </Heading>
            <Text fontSize='xl' mb={8}>
              Join hundreds of musicians and audio professionals who are already using
              Soloflow to collaborate on audio projects.
            </Text>
            <Button
              as={motion.button}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              size='lg'
              colorScheme='brand'
              fontWeight='bold'
              px={8}
              bg='white'
              color='brand.700'
              _hover={{
                bg: 'brand.700',
                color: 'white'
              }}
              onClick={() => navigate('/login')}
            >
              Start Your Free Trial
            </Button>
          </Flex>
        </Box>

        {/* Contact Form */}
        <Box py={20} id='contact'>
          <Heading as='h2' size='2xl' textAlign='center' mb={16}>
            Get in Touch
          </Heading>
          <Box maxW='600px' mx='auto'>
            <form onSubmit={handleSubmit}>
              <Stack spacing={6}>
                <Input
                  placeholder='Your Name'
                  size='lg'
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                />
                <Input
                  placeholder='Your Email'
                  size='lg'
                  type='email'
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                />
                <Textarea
                  placeholder='Your Message'
                  size='lg'
                  rows={6}
                  value={formData.message}
                  onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                />
                <Button
                  type='submit'
                  colorScheme='brand'
                  size='lg'
                  fontWeight='bold'
                  as={motion.button}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Send Message
                </Button>
              </Stack>
            </form>
          </Box>
        </Box>
      </Box>
      <Footer />
    </ChakraProvider>
  )
}

export default LandingPage
