import React from 'react'
import { Button, createIcon } from '@chakra-ui/react'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import * as amplitude from '@amplitude/analytics-browser'

import { auth } from '../../firebase'
import { handleLoginError } from './handleLoginError'

const GoogleIcon = createIcon({
  displayName: 'GoogleIcon',
  path: (
    <g transform='matrix(1, 0, 0, 1, 27.009001, -39.238998)'>
      <path
        fill='#4285F4'
        d='M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z'
      />
      <path
        fill='#34A853'
        d='M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z'
      />
      <path
        fill='#FBBC05'
        d='M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z'
      />
      <path
        fill='#EA4335'
        d='M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z'
      />
    </g>
  )
})

interface GoogleProviderProps {
  returnUrl?: string
  shouldSave: boolean
}

const GoogleProvider: React.FC<GoogleProviderProps> = ({ returnUrl, shouldSave }) => {
  const navigate = useNavigate()
  const provider = new GoogleAuthProvider()

  const login = () => {
    signInWithPopup(auth, provider)
      .then(() => {
        amplitude.track('Login', {
          userID: auth.currentUser?.uid
        })

        const firstSignIn =
          auth.currentUser?.metadata.creationTime ===
          auth.currentUser?.metadata.lastSignInTime

        let redirectUrl: URL

        if (firstSignIn) {
          // If it's the first sign-in, redirect to the survey page
          redirectUrl = new URL('/survey', window.location.origin)
          // Add returnUrl as a query parameter to the survey page
          if (returnUrl) {
            redirectUrl.searchParams.set('returnUrl', returnUrl)
          }
        } else {
          // If it's not the first sign-in, use the returnUrl or default to '/projects'
          redirectUrl = new URL(returnUrl || '/projects', window.location.origin)
        }

        // Preserve the 'save' parameter
        if (shouldSave) {
          redirectUrl.searchParams.set('save', 'true')
        }

        navigate(redirectUrl.pathname + redirectUrl.search)
      })
      .catch((error) => {
        handleLoginError(error)
      })
  }

  return (
    <Button m='2' onClick={login}>
      Sign in with Google
      <GoogleIcon ml='1.5' />
    </Button>
  )
}

export default GoogleProvider
