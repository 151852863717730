import { FirebaseOptions, initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { collection, getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
// import firebaseConfig from '@/firebaseconfig.json'

const firebaseConfig = {
  apiKey: 'AIzaSyDuF_P6QxwbYkxvvfiPgxMeb0LNDBxkz8g',
  authDomain: 'soloflow-be7d3.firebaseapp.com',
  projectId: 'soloflow-be7d3',
  storageBucket: 'soloflow-be7d3.appspot.com',
  messagingSenderId: '819746759650',
  appId: '1:819746759650:web:d46b641530ae4fc14dcfbb',
  measurementId: 'G-8J8ZVLR34P'
}

const app = initializeApp(firebaseConfig as FirebaseOptions)
const db = getFirestore(app)
const auth = getAuth(app)
const storage = getStorage(app)

// const analytics = getAnalytics(app)

const projectsDB = collection(db, 'projects')
const versionsDB = collection(db, 'versions')
const threadsDB = collection(db, 'threads')
const waitlistDB = collection(db, 'waitlist')
const segmentationDB = collection(db, 'segmentation')
const permissionsDB = collection(db, 'permissions')

export {
  app,
  db,
  auth,
  storage,
  projectsDB,
  versionsDB,
  threadsDB,
  waitlistDB,
  segmentationDB,
  permissionsDB
}
