import React, { useState } from 'react'
import {
  useToast,
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  InputGroup,
  InputRightElement,
  ModalCloseButton,
  VStack
} from '@chakra-ui/react'
import { FaUpload, FaPlus, FaTrash } from 'react-icons/fa'
import axios from 'axios'
import { ref, uploadBytes } from 'firebase/storage'
import { auth, storage } from '../../firebase'
import { useNavigate } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import * as amplitude from '@amplitude/analytics-browser'

import LoadingScreen from '../common/LoadingScreen'
import ErrorScreen from '../common/ErrorScreen'
import { BASE_URL } from '../../config'

interface CreateProjectProps {
  isOpen: boolean
  onClose: () => void
}

const CreateProject = (props: CreateProjectProps) => {
  const [trackNames, setTrackNames] = useState<string[]>([''])
  const [projectName, setProjectName] = useState('')
  const [files, setFiles] = useState<File[]>([new File([''], '')])
  const toast = useToast()
  const [user, loading, error] = useAuthState(auth)
  const navigate = useNavigate()

  const [isLoadingCreateProject, setIsLoadingCreateProject] = useState(false)

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const file = e.target.files?.[0] || null

    if (file) {
      setFiles((prevFiles) => {
        const newFiles = [...prevFiles]
        newFiles[index] = file
        return newFiles
      })
    }
  }

  const createProject = async () => {
    setIsLoadingCreateProject(true)
    try {
      if (files && user) {
        // create project
        const project = await axios.post(`${BASE_URL}/projects`, {
          name: projectName,
          numTracks: trackNames.length,
          userIDs: [user?.uid],
          clientEmails: [],
          createdBy: user.email
        })

        const { id: projectID } = project.data

        for (let i = 0; i < trackNames.length; i++) {
          if (trackNames[i] === '' || files[i].name === '') {
            continue
          }

          const track = await axios.post(`${BASE_URL}/tracks`, {
            name: `${trackNames[i]}`,
            projectID: projectID
          })

          const { id: trackID } = track.data

          const rand = Math.floor(100000 + Math.random() * 900000)
          const fileId = `${user?.uid}-${trackID}-${files[i].name}-${rand}`

          // upload file to storage bucket
          const storageRef = ref(storage, `audio/${fileId}`)
          await uploadBytes(storageRef, files[i])

          // create version
          await axios.post(`${BASE_URL}/versions`, {
            name: `Version 1`,
            trackID: trackID,
            fileID: fileId
          })

          // After successful track creation (not counting the initial version creation)
          amplitude.track('Track Created', {
            trackName: trackNames[i],
            userID: user?.uid
          })
        }

        // After successful project creation
        amplitude.track('Project Created', {
          projectName: projectName,
          numTracks: trackNames.length,
          userID: user?.uid
        })

        toast({
          title: 'Audio Uploaded Successfully!',
          description: 'Your audio has been uploaded!',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        navigate(`/projects/${projectID}`)
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: `There was an error uploading your audio. Please try again ${error}`,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    } finally {
      setIsLoadingCreateProject(false)
    }
  }

  const addTrack = () => {
    setTrackNames([...trackNames, ''])
    setFiles([...files, new File([''], '')])
  }

  const removeTrack = (index: number) => {
    setTrackNames(trackNames.filter((_, i) => i !== index))
    setFiles(files.filter((_, i) => i !== index))
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered size='lg'>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody py={8}>
          <VStack spacing={6}>
            <Heading as='h2' size='lg'>
              Create New Project
            </Heading>
            <FormControl>
              <FormLabel>Project Name</FormLabel>
              <Input
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                placeholder='My Awesome Project'
              />
            </FormControl>
            {trackNames.map((trackName, index) => (
              <Box key={index} w='100%'>
                <FormControl>
                  <FormLabel>Track {index + 1}</FormLabel>
                  <InputGroup>
                    <Input
                      value={trackName}
                      onChange={(e) => {
                        const newTrackNames = [...trackNames]
                        newTrackNames[index] = e.target.value
                        setTrackNames(newTrackNames)
                      }}
                      placeholder={`Track ${index + 1} name`}
                    />
                    <InputRightElement>
                      <Button
                        size='sm'
                        onClick={() => removeTrack(index)}
                        variant='ghost'
                        colorScheme='red'
                      >
                        <Icon as={FaTrash} />
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <FormControl mt={2}>
                  <Input
                    type='file'
                    accept='audio/*'
                    onChange={(e) => handleFileUpload(e, index)}
                    hidden
                    id={`file-input-${index}`}
                  />
                  <Button
                    as='label'
                    htmlFor={`file-input-${index}`}
                    leftIcon={<FaUpload />}
                    variant='outline'
                    colorScheme='orange'
                    w='100%'
                  >
                    {files[index].name || 'Upload Audio'}
                  </Button>
                </FormControl>
              </Box>
            ))}
            <Button
              leftIcon={<FaPlus />}
              onClick={addTrack}
              variant='ghost'
              colorScheme='green'
            >
              Add Track
            </Button>
            <Button
              colorScheme='blue'
              isLoading={isLoadingCreateProject}
              onClick={createProject}
              isDisabled={
                !projectName ||
                trackNames.some((name) => !name) ||
                files.some((file) => !file.name)
              }
              w='100%'
            >
              Create Project
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CreateProject
