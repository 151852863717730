import React, { useState } from 'react'
import {
  useToast,
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  InputGroup,
  InputRightElement,
  ModalCloseButton,
  VStack
} from '@chakra-ui/react'
import { FaPlus, FaTrash, FaUpload } from 'react-icons/fa'
import axios from 'axios'
import { ref, uploadBytes } from 'firebase/storage'
import { auth, storage } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import * as amplitude from '@amplitude/analytics-browser'

import LoadingScreen from '../common/LoadingScreen'
import ErrorScreen from '../common/ErrorScreen'
import { BASE_URL } from '../../config'
import { Project } from '../../_helpers/types'

interface CreateTrackProps {
  isOpen: boolean
  onClose: () => void
  project: Project
}

const CreateTrack = (props: CreateTrackProps) => {
  const [trackNames, setTrackNames] = useState<string[]>([''])
  const [files, setFiles] = useState<File[]>([new File([''], '')])
  const [isLoadingCreateTrack, setIsLoadingCreateTrack] = useState(false)

  const toast = useToast()
  const [user, loading, error] = useAuthState(auth)

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const file = e.target.files?.[0] || null

    if (file) {
      setFiles((prevFiles) => {
        const newFiles = [...prevFiles]
        newFiles[index] = file
        return newFiles
      })
    }
  }

  const createTrack = async () => {
    setIsLoadingCreateTrack(true)

    try {
      if (files && user && props.project) {
        for (let i = 0; i < trackNames.length; i++) {
          const track = await axios.post(`${BASE_URL}/tracks`, {
            name: `${trackNames[i]}`,
            projectID: props.project.id
          })

          const { id: trackID } = track.data

          const rand = Math.floor(100000 + Math.random() * 900000)
          const fileId = `${user?.uid}-${trackID}-${files[i].name}-${rand}`

          // upload file to storage bucket
          const storageRef = ref(storage, `audio/${fileId}`)
          await uploadBytes(storageRef, files[i])

          // create version
          await axios.post(`${BASE_URL}/versions`, {
            name: 'Version 1',
            trackID: trackID,
            fileID: fileId
          })

          amplitude.track('Track Created', {
            trackName: trackNames[i],
            projectID: props.project.id,
            userID: user?.uid
          })
        }

        const updatedNumTracks = props.project.numTracks + trackNames.length
        const updatedProject = {
          name: props.project.name,
          numTracks: updatedNumTracks,
          // userIDs: props.project.userID,
          clientEmails: props.project.clientEmails
        }
        await axios.put(`${BASE_URL}/projects/${props.project.id}`, updatedProject)

        toast({
          title: 'New Track Uploaded Successfully!',
          description: 'Your track has been uploaded!',
          status: 'success',
          duration: 5000,
          isClosable: true
        })

        window.location.reload()
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: `There was an error uploading your track. Please try again ${error}`,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    } finally {
      setIsLoadingCreateTrack(false)
    }
  }

  const addTrack = () => {
    setTrackNames([...trackNames, ''])
    setFiles([...files, new File([''], '')])
  }

  const removeTrack = (index: number) => {
    setTrackNames(trackNames.filter((_, i) => i !== index))
    setFiles(files.filter((_, i) => i !== index))
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered size='lg'>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody py={8}>
          <VStack spacing={6}>
            <Heading as='h2' size='lg'>
              Create New Tracks
            </Heading>
            {trackNames.map((trackName, index) => (
              <Box key={index} w='100%'>
                <FormControl>
                  <FormLabel>Track {index + 1}</FormLabel>
                  <InputGroup>
                    <Input
                      value={trackName}
                      onChange={(e) => {
                        const newTrackNames = [...trackNames]
                        newTrackNames[index] = e.target.value
                        setTrackNames(newTrackNames)
                      }}
                      placeholder={`Track ${index + 1} name`}
                    />
                    <InputRightElement>
                      <Button
                        size='sm'
                        onClick={() => removeTrack(index)}
                        variant='ghost'
                        colorScheme='red'
                      >
                        <Icon as={FaTrash} />
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <FormControl mt={2}>
                  <Input
                    type='file'
                    accept='audio/*'
                    onChange={(e) => handleFileUpload(e, index)}
                    hidden
                    id={`file-input-${index}`}
                  />
                  <Button
                    as='label'
                    htmlFor={`file-input-${index}`}
                    leftIcon={<FaUpload />}
                    variant='outline'
                    colorScheme='orange'
                    w='100%'
                  >
                    {files[index].name || 'Upload Audio'}
                  </Button>
                </FormControl>
              </Box>
            ))}
            <Button
              leftIcon={<FaPlus />}
              onClick={addTrack}
              variant='ghost'
              colorScheme='green'
            >
              Add Track
            </Button>
            <Button
              colorScheme='blue'
              isLoading={isLoadingCreateTrack}
              onClick={createTrack}
              isDisabled={
                !trackNames ||
                trackNames.some((name) => !name) ||
                files.some((file) => !file.name)
              }
              w='100%'
            >
              Create Track
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CreateTrack
