import React, { useEffect } from 'react'
import { Box, Button, HStack, Text } from '@chakra-ui/react'
import { useAuthState } from 'react-firebase-hooks/auth'

import { auth } from '../../firebase'
import EmailPasswordLoginForm from '../auth/EmailPasswordLoginForm'
import RegistrationPageTemplate from '../auth/RegistrationPageTemplate'
import LoadingScreen from '../common/LoadingScreen'
import MobileAccessErrorScreen from '../common/MobileAccessErrorScreen'
import ErrorScreen from '../common/ErrorScreen'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga4'

const Login: React.FC<{ setShowSidebar: (show: boolean) => void }> = ({
  setShowSidebar
}) => {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
    name: 'Login'
  })

  useEffect(() => {
    setShowSidebar(false)
  }, [])

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  }

  const [user, loading, error] = useAuthState(auth)
  const navigate = useNavigate()
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const returnUrl = queryParams.get('returnUrl') || undefined
  const shouldSave = queryParams.get('save') === 'true'

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  if (isMobile()) {
    return <MobileAccessErrorScreen home='/' />
  }

  if (user && !loading) {
    if (!user.emailVerified) {
      navigate('/verify-email')
    } else {
      navigate(returnUrl || '/projects')
    }
  }

  return (
    <Box>
      <RegistrationPageTemplate
        headingText='Log in to your account'
        emailPasswordComponent={<EmailPasswordLoginForm returnUrl={returnUrl} />}
        helpComponent={
          <HStack spacing='1' justify='center' mt={4}>
            <Text>Don&apos;t have an account?</Text>
            <Link to={`/signup${returnUrl ? `?returnUrl=${returnUrl}` : ''}`}>
              <Button variant='link' colorScheme='blue'>
                Sign up
              </Button>
            </Link>
          </HStack>
        }
        returnUrl={returnUrl}
        shouldSave={shouldSave}
      />
    </Box>
  )
}

export default Login
