import React, { useState, useEffect } from 'react'
import {
  Box,
  VStack,
  Heading,
  CheckboxGroup,
  Stack,
  Checkbox,
  HStack,
  Button,
  Container
} from '@chakra-ui/react'
import { auth, segmentationDB } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import LoadingScreen from './LoadingScreen'
import ErrorScreen from './ErrorScreen'
import { useNavigate, useLocation } from 'react-router-dom'
import { doc, getDoc, setDoc } from 'firebase/firestore'
// import Footer from './Footer'

const CustomerSegmentation: React.FC<{ setShowSidebar: (show: boolean) => void }> = ({
  setShowSidebar
}) => {
  const [user, loading, error] = useAuthState(auth)
  const [work, setWork] = useState<string[]>([])
  const [audioSpace, setAudioSpace] = useState<string[]>([])
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    setShowSidebar(false)
  }, [])

  useEffect(() => {
    const checkSegmentationDoc = async () => {
      if (user) {
        try {
          const userSurvey = await getDoc(doc(segmentationDB, user.uid))
          if (userSurvey.exists()) {
            // User has already taken survey, redirect them
            handleRedirect()
          }
        } catch (error) {
          console.error('Error checking segmentation document:', error)
          handleRedirect()
        }
      }
    }

    checkSegmentationDoc()
  }, [user])

  const handleRedirect = () => {
    const searchParams = new URLSearchParams(location.search)
    const returnUrl = searchParams.get('returnUrl') || '/projects'
    const save = searchParams.get('save')

    const redirectUrl = new URL(returnUrl, window.location.origin)
    if (save) {
      redirectUrl.searchParams.set('save', save)
    }

    navigate(redirectUrl.pathname + redirectUrl.search)
  }

  const handleSubmit = async () => {
    if (user) {
      await setDoc(doc(segmentationDB, user.uid), {
        userId: user.uid,
        work,
        audioSpace
      })
    }
    handleRedirect()
  }

  const onSkip = () => {
    handleRedirect()
  }

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  return (
    <Container maxW='container.md'>
      <Box borderRadius='lg' p={6}>
        <VStack spacing={8} align='stretch'>
          <Heading size='lg' textAlign='center'>
            Welcome! Help us understand your needs
          </Heading>

          <Box>
            <Heading size='md' mb={4}>
              Your work
            </Heading>
            <CheckboxGroup
              colorScheme='orange'
              value={work}
              onChange={(values) => setWork(values as string[])}
            >
              <Stack spacing={3}>
                <Checkbox
                  value='Audio Professional collaborating with another Audio Professional'
                  borderColor='orange'
                >
                  Audio Professional collaborating with another Audio Professional
                </Checkbox>
                <Checkbox
                  value='Audio Professional working with clients'
                  borderColor='orange'
                >
                  Audio Professional working with clients
                </Checkbox>
                <Checkbox
                  value='Client working with Audio Professional'
                  borderColor='orange'
                >
                  Client working with Audio Professional
                </Checkbox>
                <Checkbox value='Other' borderColor='orange'>
                  Other
                </Checkbox>
              </Stack>
            </CheckboxGroup>
          </Box>

          <Box>
            <Heading size='md' mb={4}>
              Audio Space
            </Heading>
            <CheckboxGroup
              colorScheme='orange'
              value={audioSpace}
              onChange={(values) => setAudioSpace(values as string[])}
            >
              <Stack spacing={3}>
                <Checkbox value='Mashups & Mixes' borderColor='orange'>
                  Mashups & Mixes
                </Checkbox>
                <Checkbox value='Vocalist' borderColor='orange'>
                  Vocalist
                </Checkbox>
                <Checkbox value='Audio Engineer' borderColor='orange'>
                  Audio Engineer
                </Checkbox>
                <Checkbox value='Music Production' borderColor='orange'>
                  Music Production
                </Checkbox>
                <Checkbox value='Podcaster' borderColor='orange'>
                  Podcaster
                </Checkbox>
                <Checkbox value='Voice Actor & Narrator' borderColor='orange'>
                  Voice Actor & Narrator
                </Checkbox>
                <Checkbox value='Other' borderColor='orange'>
                  Other
                </Checkbox>
              </Stack>
            </CheckboxGroup>
          </Box>

          <HStack spacing={4} justifyContent='center'>
            <Button colorScheme='orange' onClick={handleSubmit} size='lg'>
              Submit
            </Button>
            <Button variant='outline' onClick={onSkip} size='lg'>
              Skip for now
            </Button>
          </HStack>
        </VStack>
      </Box>
      {/* <Footer /> */}
    </Container>
  )
}

export default CustomerSegmentation
