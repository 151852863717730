import React, { useState, useMemo } from 'react'
import {
  Box,
  Flex,
  Text,
  VStack,
  HStack,
  Badge,
  IconButton,
  Avatar,
  Button,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
  RadioGroup,
  Radio,
  Checkbox,
  Divider,
  Tag,
  Tooltip
} from '@chakra-ui/react'
import { FaEllipsisV, FaFilter } from 'react-icons/fa'
import ThreadModal from './ThreadModal'
import { CheckIcon } from '@chakra-ui/icons'
import axios from 'axios'
import { BASE_URL } from '../config'
import { Thread } from '../_helpers/types'
import * as amplitude from '@amplitude/analytics-browser'
import { auth } from '../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Role } from '../api/permission/permissions'

const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = Math.floor(Math.ceil(seconds) % 60) // should we ceil the seconds?
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
}

type ThreadCarouselProps = {
  threads: Thread[]
  refetchThreads: () => void
  handleThreadClick: (region: any) => void // eslint-disable-line @typescript-eslint/no-explicit-any
  isOwner: boolean
  roles: Role[]
}

const ThreadCarousel: React.FC<ThreadCarouselProps> = ({
  threads,
  refetchThreads,
  handleThreadClick,
  isOwner,
  roles
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedThread, setSelectedThread] = useState<Thread | null>(null)
  const [user] = useAuthState(auth)
  const toast = useToast()

  const bgColor = 'beige'
  const textColor = 'black'
  const subTextColor = 'gray.600'
  const cardBgColor = 'orange.100'

  const [filterState, setFilterState] = useState<'all' | 'resolved' | 'unresolved'>('all')
  const [filterUsers, setFilterUsers] = useState<Set<string>>(new Set())

  const uniqueUsers = useMemo(() => {
    return Array.from(new Set(threads.map((thread) => thread.messages[0].sender)))
  }, [threads])

  const filteredThreads = useMemo(() => {
    return threads
      .filter((thread) => {
        const stateFilter =
          filterState === 'all' ||
          (filterState === 'resolved' && thread.isResolved) ||
          (filterState === 'unresolved' && !thread.isResolved)
        const userFilter =
          filterUsers.size === 0 || filterUsers.has(thread.messages[0].sender)
        return stateFilter && userFilter
      })
      .sort((a: Thread, b: Thread) => {
        if (a.isResolved && !b.isResolved) {
          return 1
        } else if (!a.isResolved && b.isResolved) {
          return -1
        } else {
          if (a.messages[0].timestamp > b.messages[0].timestamp) {
            return 1
          } else {
            return -1
          }
        }
      })
  }, [threads, filterState, filterUsers])

  const handleUserFilterChange = (user: string) => {
    setFilterUsers((prevUsers) => {
      const newUsers = new Set(prevUsers)
      if (newUsers.has(user)) {
        newUsers.delete(user)
      } else {
        newUsers.add(user)
      }
      return newUsers
    })
  }

  const handleOpenReplies = (thread: Thread) => {
    setSelectedThread(thread)
    onOpen()
  }

  const handleResolveThread = async (thread: Thread) => {
    if (thread) {
      try {
        await axios.put(`${BASE_URL}/threads/${thread.id}`, { isResolved: true })
        toast({
          title: 'Thread resolved!',
          status: 'success',
          duration: 3000,
          isClosable: true
        })

        amplitude.track('Thread Resolved', {
          threadID: thread.id,
          userID: user?.uid
        })

        // TODO: make this not a refresh
        refetchThreads()
      } catch (error) {
        console.error('Failed to resolve thread:', error)
        toast({
          title: 'Failed to resolve thread!',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }
    }
  }

  const handleCardClick = (thread: Thread) => {
    if (
      thread.region &&
      thread.region.start !== undefined &&
      thread.region.end !== undefined
    ) {
      handleThreadClick(thread.region)
    }
  }

  return (
    <Box
      mx='auto'
      mb={8}
      bg={bgColor}
      borderRadius='lg'
      overflow='hidden'
      boxShadow='lg'
      maxHeight='44vh'
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
    >
      <Flex justifyContent='space-between' align='center' p={4}>
        <Menu closeOnSelect={false}>
          <MenuButton as={Button} leftIcon={<FaFilter />} colorScheme='orange' ml='auto'>
            {filteredThreads.length} comments
          </MenuButton>
          <MenuList>
            <MenuItem _hover={{ bg: 'white' }}>
              <RadioGroup
                onChange={(value) =>
                  setFilterState(value as 'all' | 'resolved' | 'unresolved')
                }
                value={filterState}
              >
                <VStack align='start'>
                  <Text fontWeight='bold' mb={2}>
                    Filter By State
                  </Text>
                  <Radio value='unresolved'>Show Unresolved</Radio>
                  <Radio value='resolved'>Show Resolved</Radio>
                  <Radio value='all'>Show All</Radio>
                </VStack>
              </RadioGroup>
            </MenuItem>
            <Divider my={2} />
            <MenuItem _hover={{ bg: 'white' }}>
              <VStack align='start'>
                <Text fontWeight='bold' mb={2}>
                  Filter By Commenter
                </Text>
                {uniqueUsers.map((user) => (
                  <Checkbox
                    key={user}
                    isChecked={filterUsers.has(user)}
                    onChange={() => handleUserFilterChange(user)}
                  >
                    <HStack>
                      <Avatar name={user} size='xs' />
                      <Text>{user}</Text>
                    </HStack>
                  </Checkbox>
                ))}
              </VStack>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Box display='flex' overflowX='auto' px={10} height='30vh'>
        {threads.length > 0 ? (
          filteredThreads.length > 0 ? (
            <HStack spacing={6}>
              {filteredThreads.map((thread, index) => (
                <Box
                  key={index}
                  width='350px'
                  height='170px'
                  bg={cardBgColor}
                  borderRadius='lg'
                  boxShadow='md'
                  p={4}
                  onClick={() => handleCardClick(thread)}
                  cursor='pointer'
                >
                  <VStack
                    align='stretch'
                    spacing={3}
                    display='flex'
                    justifyContent='space-between'
                    height='100%'
                  >
                    <Flex justify='space-between' align='center'>
                      <HStack>
                        <Avatar name={thread.messages[0].sender} size='sm' />
                        <Text fontWeight='medium' color={textColor}>
                          {thread.messages[0].sender}
                        </Text>
                      </HStack>
                      <HStack>
                        <Text fontSize='sm' color={subTextColor}>
                          {formatTime(thread.region.start)}
                        </Text>
                        {thread.isResolved && (
                          <Tooltip label='Resolved'>
                            <Tag
                              as={IconButton}
                              icon={<CheckIcon />}
                              color='green.500'
                              bg='green.100'
                              borderRadius='full'
                              width='auto'
                              height='auto'
                              _hover={{ bg: 'green.100' }}
                            />
                          </Tooltip>
                        )}
                        {isOwner ||
                          (roles.includes(Role.MANAGE) && (
                            <Menu>
                              <MenuButton
                                as={IconButton}
                                icon={<FaEllipsisV />}
                                colorScheme='orange'
                                variant='ghost'
                                size='sm'
                              />
                              <MenuList>
                                <MenuItem onClick={() => handleResolveThread(thread)}>
                                  Resolve
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          ))}
                      </HStack>
                    </Flex>
                    <Tooltip label={thread.messages[0].content} placement='top-start'>
                      <Text
                        color={textColor}
                        fontWeight='semibold'
                        noOfLines={2}
                        overflow='hidden'
                        textOverflow='ellipsis'
                      >
                        {thread.messages[0].content}
                      </Text>
                    </Tooltip>
                    <Flex justify='space-between' align='center' mt='auto'>
                      {thread.messages.length > 1 && (
                        <Badge
                          colorScheme='purple'
                          borderRadius='full'
                          px={2}
                          py={1}
                          cursor='pointer'
                          onClick={() => handleOpenReplies(thread)}
                        >
                          {thread.messages.length - 1}{' '}
                          {thread.messages.length - 1 === 1 ? 'reply' : 'replies'}
                        </Badge>
                      )}
                      <Box display='flex' justifyContent='flex-end' width='100%'>
                        <Button
                          size='sm'
                          colorScheme='orange'
                          borderRadius='full'
                          onClick={() => handleOpenReplies(thread)}
                        >
                          Reply
                        </Button>
                      </Box>
                    </Flex>
                  </VStack>
                </Box>
              ))}
            </HStack>
          ) : null // When filteredThreads is empty, don't display anything
        ) : (
          <Box
            display='flex'
            alignContent='center'
            justifyContent='center'
            width='100%'
            height='auto'
          >
            <Text
              textAlign='center'
              fontSize='lg'
              fontWeight='bold'
              color='blue.400'
              width='50%'
              height='auto'
              alignSelf='center'
              justifySelf='center'
            >
              {'No threads found! Drag out a region and click on it '}
              {'to create your first thread! 🚀'}
            </Text>
          </Box>
        )}
      </Box>

      {/* Replies Modal */}
      <ThreadModal
        isOpen={isOpen}
        onClose={onClose}
        versionID={selectedThread?.versionID}
        region={selectedThread?.region}
        threadParam={selectedThread}
        filePath={selectedThread?.region.filePath}
        refetchThreads={refetchThreads}
        isOwner={isOwner}
        roles={roles}
      />
    </Box>
  )
}

export default ThreadCarousel
